import { QueryClient } from '@tanstack/react-query'
import { queryClient } from 'config/tanstack-query'
import { Themes } from 'feature/themes/themes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Callback from 'pages/callback'
import FAQPage from 'pages/faq/faq'
import Saml from 'pages/sso/saml'
import { lazy } from 'react'
import { LoaderFunction, Navigate, NonIndexRouteObject, Outlet, Route, Routes } from 'react-router-dom'
import { retry } from 'utils/promise-rety'

import ProtectedRoute from './protected-route'
import PublicRoute from './public-route'
import { benefitRoutes, routesMap } from './routes-map'
import { preparePath } from './utils/prepareRoutes'

const Home = lazy(() => retry(() => import('pages/home')))
const Terms = lazy(() => retry(() => import('pages/terms')))
const DataUsePolicy = lazy(() => retry(() => import('pages/data-use-policy')))
const Register = lazy(() => retry(() => import('pages/register')))
const UnauthLogin = lazy(() => retry(() => import('pages/unauth-home/layouts/UnauthLogin')))
const MyProfile = lazy(() => retry(() => import('pages/my-profile')))
const Search = lazy(() => retry(() => import('pages/search')))
const AutoquotesHome = lazy(() => retry(() => import('pages/autoquotes')))
const ForgotPassword = lazy(() => retry(() => import('pages/unauth-home/forgot-password/forgot-password')))
const BenefitCategories = lazy(() =>
	retry(() =>
		import('pages/benefit-categories/benefit-categories').then((module) => ({ default: module.BenefitCategories })),
	),
)
const DealsLandingPage = lazy(() => retry(() => import('pages/deals')))
const Plan = lazy(() => retry(() => import('pages/plan')))
const Wizard = lazy(() => retry(() => import('pages/wizard')))
const WorkerRedirect = lazy(() => retry(() => import('pages/worker-redirect')))
const Survey = lazy(() => retry(() => import('pages/survey')))
const ComingSoon = lazy(() => retry(() => import('pages/unauth-home/coming-soon/coming-soon')))
const Sandbox = lazy(() => retry(() => import('pages/sandbox/sandbox')))
const ExperimentRedirect = lazy(() => retry(() => import('./experimentRedirect')))
const Account = lazy(() => retry(() => import('pages/account/account-page')))

type CurriedLoaderFunction = (queryClient: QueryClient) => LoaderFunction<any>

const applyQueryClientToModule = (
	module: { loader?: CurriedLoaderFunction | undefined } & Omit<NonIndexRouteObject, 'loader'>,
): any => {
	const curriedLoader = module.loader

	if (!curriedLoader) return module as NonIndexRouteObject

	const loader = curriedLoader(queryClient)

	return {
		...module,
		loader,
	} as NonIndexRouteObject
}

// a very clunky fallback-less flag guard
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FlagGuard = ({ evaluate, flag }: { evaluate: (v: any) => boolean; flag: string }) => {
	const { [flag]: flagValue } = useFlags()

	const result = evaluate(flagValue)

	if (result) {
		return <Outlet />
	}

	return null
}

/**
 * routes within this need to be relative instead of absolute
 *
 * If you have nested routes, you technically don't need to do path='parent/nested' you can do path='nested'
 *
 * If using tanstack-query in your loader: (THIS MAY GO AWAY IN RRv7 via the new context system)
 * - make a curried loader function that allows you to pass in queryClient
 * - manually apply queryClient via a chained like so:
 *     import('path').then((module) => ({ ...module, loader: module.loader(queryClient) }))
 */
export const DataLoaderCapableRoutes = (
	<Route>
		<Route lazy={() => import('pages/404')} path={preparePath(routesMap.missing)} />
		<Route element={<ProtectedRoute />} path={routesMap.deals._route}>
			<Route element={<DealsLandingPage />} index />
			<Route
				lazy={() => import('pages/deals/travel').then((module) => applyQueryClientToModule(module))}
				path={routesMap.deals.travel._route}
			/>
		</Route>
	</Route>
)

/**
 * Please follow new Route Component, loader, FallbackElement pattern instead of adding here
 *
 * These routes need migrated and will be migrated soon, so please don't add to our work.
 *
 * Questions? Read the docs here: https://reactrouter.com/en/main/route/lazy#lazy
 * PS - yes that's the lazy docs, read the example inside for how you can declare all route components in a single file
 *
 * Further questions - reach out to Colin to guide you through the process
 */
export const NeedingToBeMigratedRoutes = () => {
	const { wrp2994 } = useFlags()

	return (
		<Routes>
			<Route element={<PublicRoute />}>
				{[routesMap.start, routesMap.error].map((path) => (
					<Route key={path} element={<UnauthLogin />} path={path} />
				))}
				<Route element={<UnauthLogin />} path={`${routesMap.login}/*`} />
				<Route element={<UnauthLogin />} path={routesMap.adminLogin} />
				<Route element={<ForgotPassword />} path={routesMap.forgotPassword} />
				<Route element={<Register />} path={routesMap.register} />
				<Route element={<ComingSoon />} path={routesMap.comingSoon} />
				<Route element={<UnauthLogin />} path={routesMap.logout} />
			</Route>
			<Route element={<FAQPage />} path={routesMap.faq} />
			<Route element={<Terms />} path={routesMap.terms} />
			<Route element={<Callback />} path={routesMap.callback} />
			<Route element={<DataUsePolicy />} path={routesMap.dataUsePolicy} />
			<Route element={<Saml />} path={routesMap.saml} />
			<Route element={<Sandbox />} path='sandbox' />
			<Route element={<ProtectedRoute />}>
				<Route element={<ExperimentRedirect />} path='/e' />
				<Route element={<Navigate to={benefitRoutes.wizard.base} />} path={routesMap.enrollmentRedirect} />
				<Route element={<Navigate to={benefitRoutes.wizard.base} />} path='/enroll/*' />
				<Route element={<Home />} path={routesMap.home} />
				<Route element={<Account />} path={`${routesMap.account}/*`} />
				<Route element={<MyProfile />} path={routesMap.myProfile} />
				<Route element={<Search />} path={routesMap.search} />
				<Route element={<AutoquotesHome />} path={`${routesMap.autoQuote}/*`} />
				<Route element={<WorkerRedirect />} path={routesMap.workerRedirect} />
				{wrp2994 && <Route element={<BenefitCategories />} path={routesMap.benefitCategory} />}
				<Route element={<Navigate replace to='/home' />} path='/' />
				<Route element={<Plan />} path={benefitRoutes.benefit._route} />
				<Route element={<Wizard />} path={benefitRoutes.wizard._route} />
				<Route element={<Survey />} path={routesMap.survey._route} />
				<Route element={<Themes />} path={routesMap.themes._route} />
			</Route>
			<Route element={<Navigate replace to='/home' />} path='*' />
		</Routes>
	)
}
