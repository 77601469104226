import { not, numericSearchPresets, OFFER_FILTERS, searchPresets } from 'api/search'
import { SearchFilters } from 'api/search/types/search'
import { BENEFIT_PLAN_CARD_ATTRIBUTES } from 'api/search/views/benefit_plans'
import { OFFER_CARD_ATTRIBUTES } from 'api/search/views/offers'
import { usePrefetchClickedOffers } from 'api/tenants/queries'
import { useEffectOnceWithRef } from 'hooks/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { ConfiguredIndices } from 'state/slices/search'
import { useSearchSlice } from 'state/store'
import { isSiteLoadingSelector, siteInfoSelector } from 'store/site/selectors'
import { isLoggedInSelector, myProfileSelector } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'

import { useInitFromURL, useSearchURLSync } from './use-search-url'

const indexes: ConfiguredIndices[] = ['offers', 'benefit_plans']

export const useSearchInit = (isLoggedIn: boolean) => {
	const { organizationId, properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const siteInfoLoading = useSelector(isSiteLoadingSelector)
	const prefetchClickedOffers = usePrefetchClickedOffers()
	const searchSlice = useSearchSlice()
	const userProfile = useSelector(myProfileSelector)
	const { wrp2642 } = useFlags()

	const initFromURL = useInitFromURL(indexes)

	// Keep URL sync functionality
	useSearchURLSync({
		indices: indexes,
		writeToURL: true,
	})

	useEffectOnceWithRef(isLoggedIn && !siteInfoLoading, () => {
		searchSlice.initializeIndices(indexes)

		// Initialize from URL first
		initFromURL()

		// Then set up index configurations
		// offers index config
		const conditionalOfferFilters: SearchFilters = {}

		if (!wrp2642) {
			conditionalOfferFilters[not(OFFER_FILTERS.STATUS)] = searchPresets.activeStatus()[not(OFFER_FILTERS.STATUS)]
		}

		const blacklist = (properties.discounterBlacklist ?? []).filter((id) => id !== '')
		if (blacklist.length > 0) {
			conditionalOfferFilters[not(OFFER_FILTERS.VENDOR_DISCOUNTER_ID)] = blacklist
		}

		if (properties.ppdsTenantCode?.toLowerCase() === 'cvs' && userProfile?.employmentType?.toLowerCase() === 'ft30') {
			conditionalOfferFilters[not(OFFER_FILTERS.VENDOR_NAME)] =
				searchPresets.cvsNonNativePet()[not(OFFER_FILTERS.VENDOR_NAME)]
		}

		const finalOfferFilters: SearchFilters = {
			...searchPresets.tenantAccess(organizationId),
			...conditionalOfferFilters,
		}

		searchSlice.updateIndexParams('offers', {
			attributesToHighlight: [],
			attributesToRetrieve: OFFER_CARD_ATTRIBUTES as unknown as string[],
			facetConfig: {
				categoryNames: {
					combineWithOr: true,
				},
			},
			filters: finalOfferFilters,
			numericFilters: numericSearchPresets.activeTimeframe(),
			userToken: userProfile.userId,
		})

		searchSlice.setIndexStatus('offers', 'idle')

		// benefit plans index config
		if (properties.hasVoluntaryBenefits && !userProfile.isDiscountsOnly) {
			const conditionalBenefitFilters: SearchFilters = {
				...(!wrp2642 ? searchPresets.activeStatus() : {}),
			}

			// do not use elligible plans here - do that locally to save time
			searchSlice.updateIndexParams('benefit_plans', {
				attributesToHighlight: [],
				attributesToRetrieve: BENEFIT_PLAN_CARD_ATTRIBUTES as unknown as string[],
				facetConfig: {},
				filters: {
					...searchPresets.orgAccess(organizationId),
					...conditionalBenefitFilters,
				},
				numericFilters: numericSearchPresets.activeTimeframe(),
				userToken: userProfile.userId,
			})

			searchSlice.setIndexStatus('benefit_plans', 'idle')
		}

		// prefetch clicked offers for homepage
		prefetchClickedOffers()
	})

	return
}

export const useSearchPageInit = () => {
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)
	const siteInfoLoading = useSelector(isSiteLoadingSelector)
	const initFromURL = useInitFromURL(indexes)

	useEffectOnceWithRef(isLoggedIn && !siteInfoLoading, () => {
		initFromURL()
	})

	return initFromURL
}
