import { Campaign } from 'feature/home/layouts/product-values-home/variants/default/default-campaigns'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { LDContext, LDFlagSet } from 'launchdarkly-react-client-sdk'

import { getUTMParamsFromSessionStorage } from './cookies/utm-tracking'

export const ldIdentify = async (
	ldClient: LDClient | undefined,
	organizationName: string,
	userId: string,
	employeeId: string,
	employmentGroup?: string,
	employmentType?: string,
) => {
	const userContext: LDContext = {
		anonymous: false,
		employeeId,
		employmentGroup: employmentGroup ?? '',
		employmentType: employmentType ?? '',
		key: userId,
		kind: 'user',
		organization: organizationName, // remove at future date once LD 3.0 is working (this is now handled in orgContext)
		...getUTMParamsFromSessionStorage(),
	}

	const orgContext: LDContext = {
		key: userId,
		kind: 'organization',
		name: organizationName,
	}

	const multiContext: LDContext = {
		kind: 'multi',
		organization: orgContext,
		user: userContext,
	}

	await ldClient?.identify(multiContext)
}

export interface CustomVariationFlags extends LDFlagSet {
	b4b_homepagecampaign: {
		campaigns: Campaign[]
		enabled: boolean | undefined
	}
}
