import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Beneficiary } from 'api/benefit-elections'
import { appInsights } from 'config/app-insights'
import isAfter from 'date-fns/isAfter'
import { BenefitElectionsService } from 'services/benefit-elections-service'
import { EnrollmentService } from 'services/enrollments.service'
import { WorkerService } from 'services/worker.service'
import { AppThunkAction } from 'store/util-types/types'
import { normalize } from 'store/utils'
import { RecentElection } from 'types/recent-election'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { BenefitElectionsOverviewResponse, UserElection } from 'types/user-elections'
import { RecentUserEnrollmentRes, UserEnrollmentRes } from 'types/user-enrollment'

import { EnrollmentsState, OverviewSuccessData, ParsedOverviewData, RecentUserEnrollment } from './types'
import { parseElectionData, parseOverviewData } from './util'

const initialState: EnrollmentsState = {
	allLatestUserEnrollments: {},
	beneficiaries: [],
	isSubmittingEnrollment: IDLE,
	qleEvents: null,
	qleEventsError: null,
	qleEventsStatus: IDLE,
	qleSubmissions: [],
	recentUserEnrollment: {},
	recentUserEnrollmentError: null,
	recentUserEnrollmentStatus: IDLE,
	userEnrollmentOverview: {},
	userEnrollmentOverviewError: null,
	userEnrollmentOverviewStatus: IDLE,
}

export const enrollmentsSlice = createSlice({
	initialState,
	name: 'enrollments',
	reducers: {
		addLatestUserEnrollment(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes>): void {
			state.allLatestUserEnrollments = {
				...state.allLatestUserEnrollments,
				[action.payload.benefitPlanId]: action.payload,
			}
		},
		addRecentEnrollment(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes>): void {
			if (isAfter(new Date(action.payload.planEffectiveDate), new Date())) {
				state.recentUserEnrollment = { ...state.recentUserEnrollment, [action.payload.benefitPlanId]: action.payload }
			}
		},
		/**
		 * Update recentUserEnrollment props of slice.
		 * Decided to keep the properties as is for now instead of
		 * adding new props just for a name change. We can change them
		 * with the removal of und3484
		 */
		getRecentElectionsSuccess(state: EnrollmentsState, action: PayloadAction<RecentElection[]>): void {
			state.recentUserEnrollment = normalize(action.payload, 'benefitPlanId', true) as RecentElection

			state.recentUserEnrollmentError = null
			state.recentUserEnrollmentStatus = SUCCESS
		},
		/**
		 * This can be renamed to getRecentUserEnrollmentsError with the removal of flag und3484
		 */
		getRecentUserEnrollmentsError(state: EnrollmentsState, action: PayloadAction<Error>): void {
			state.recentUserEnrollmentError = action.payload
			state.recentUserEnrollmentStatus = ERROR
		},
		/**
		 * @deprecated replaced with getRecentElectionsSuccess under flag und3484
		 */
		getRecentUserEnrollmentsSuccess(state: EnrollmentsState, action: PayloadAction<RecentUserEnrollmentRes[]>): void {
			const allNativeEnrollments = action.payload.filter((e) => e.isNative)
			state.recentUserEnrollment = normalize(allNativeEnrollments, 'benefitPlanId') as RecentUserEnrollment

			state.recentUserEnrollmentError = null
			state.recentUserEnrollmentStatus = SUCCESS
		},
		getUserElectionsSuccess(state: EnrollmentsState, action: PayloadAction<OverviewSuccessData>): void {
			/**
			 * allLatestUserEnrollments will not be necessary when we switch to the benefit elections
			 * version of the overview endpoint. This is here as a stopgap. We are currently only
			 * using the overview data from benefit elections for the my benefits page and the enrollment
			 * banner for the product cards/pages. However, we need data in the allLatestUserEnrollments prop
			 * for the wizard to work because we have not made changes for the wizard to use benefit elections
			 * overview data. We dont plan to either so for now we will do this:
			 */
			state.allLatestUserEnrollments = Object.values(action.payload.parsedElectionData).reduce((acc, bp) => {
				return { ...acc, [bp[0].benefitPlanId]: bp[0] }
			}, {})
			state.userEnrollmentOverview = action.payload.parsedElectionData
			state.userEnrollmentOverviewError = null
			state.userEnrollmentOverviewStatus = SUCCESS
			state.beneficiaries = action.payload.beneficiaries ?? []
		},
		/**
		 * @deprecated Replaced with getUserElectionsSuccess under flag und3484
		 */
		getUserEnrollmentOverviewSuccess(state: EnrollmentsState, action: PayloadAction<ParsedOverviewData>): void {
			state.allLatestUserEnrollments = action.payload.allLatestUserEnrollments
			state.userEnrollmentOverview = action.payload.userEnrollmentOverview

			state.userEnrollmentOverviewError = null
			state.userEnrollmentOverviewStatus = SUCCESS
		},
		loadingRecentUserEnrollments(state: EnrollmentsState): void {
			state.recentUserEnrollmentStatus = LOADING
		},
		loadingUserEnrollmentOverview(state: EnrollmentsState): void {
			state.userEnrollmentOverviewStatus = LOADING
		},
		resetRecentUserEnrollmentStatus: (state: EnrollmentsState): void => {
			state.recentUserEnrollmentStatus = IDLE
		},
		resetSubmitStatus: (state: EnrollmentsState): void => {
			state.isSubmittingEnrollment = IDLE
		},
	},
})

// Extract the action creators object and the reducer
const { actions, reducer } = enrollmentsSlice
// Export the reducer and actions
export { actions, reducer as enrollments }

export const getRecentUserEnrollments =
	(und3484: boolean): AppThunkAction =>
	async (dispatch): Promise<PayloadAction<Error | RecentElection[] | RecentUserEnrollmentRes[]>> => {
		dispatch(actions.loadingRecentUserEnrollments())

		try {
			const recentUserEnrollments: any | RecentElection[] | RecentUserEnrollmentRes[] = und3484
				? await BenefitElectionsService.GetRecentElections()
				: await WorkerService.getRecentUserEnrollments()

			if (und3484) {
				dispatch(actions.getRecentElectionsSuccess(recentUserEnrollments))
			} else {
				dispatch(actions.getRecentUserEnrollmentsSuccess(recentUserEnrollments))
			}

			return recentUserEnrollments
		} catch (error) {
			appInsights.trackException({
				exception: error as Error,
				properties: { Caller: 'getRecentUserEnrollments' },
			})

			return dispatch(actions.getRecentUserEnrollmentsError(error as Error))
		}
	}

type GetOverviewParams = {
	shouldClearLists?: boolean
	und3484: boolean
}

/**
 * Fetch enrollment overview data from api
 * @param shouldClearLists used to clear enrolled and waived arrays
 *  from store when enrollments are cleared via my-profile.tsx
 * @returns UserEnrollmentRes[]
 */
export const getUserEnrollmentOverview =
	({ shouldClearLists = false, und3484 }: GetOverviewParams): AppThunkAction =>
	async (dispatch, getState): Promise<PayloadAction<Error | RecentUserEnrollmentRes[]>> => {
		const { benefitPlans } = getState()
		dispatch(actions.loadingUserEnrollmentOverview())

		try {
			let userEnrollmentOverview: any | BenefitElectionsOverviewResponse | UserElection[] | UserEnrollmentRes[]

			if (shouldClearLists) {
				// clear benefits wizard
				dispatch(actions.resetSubmitStatus())
			}

			if (und3484) {
				userEnrollmentOverview = await BenefitElectionsService.getUserElections()
				const beneficiaries = userEnrollmentOverview.beneficiaries.map((b: Beneficiary) => ({
					...b,
					clientSideId: self.crypto.randomUUID(),
				}))
				const parsedElectionData = parseElectionData(userEnrollmentOverview.elections)
				dispatch(
					actions.getUserElectionsSuccess({
						beneficiaries,
						parsedElectionData,
						workerEmailAddress: userEnrollmentOverview.workerEmailAddress,
					}),
				)
			} else {
				userEnrollmentOverview = await EnrollmentService.getEnrollmentOverview()
				const parsedOverviewData = parseOverviewData(userEnrollmentOverview, benefitPlans.availablePlans)
				dispatch(actions.getUserEnrollmentOverviewSuccess(parsedOverviewData))
			}

			return userEnrollmentOverview
		} catch (error) {
			appInsights.trackException({
				exception: error as Error,
				properties: { Caller: 'getUserEnrollmentOverview' },
			})

			return dispatch(actions.getRecentUserEnrollmentsError(error as Error))
		}
	}
