import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web'
import { parseEnvAndDomainFromHost } from 'app/utils/parseEnvAndDomainFromHost'
import { Environments } from 'utils/env'

const getAppInsightsKey = () => {
	const env = parseEnvAndDomainFromHost(window.location.host).env

	switch (env) {
		case Environments.INT:
			return import.meta.env.VITE_APPLICATION_INSIGHTS_KEY_INT
		case Environments.PROD:
			return import.meta.env.VITE_APPLICATION_INSIGHTS_KEY
		case Environments.UAT:
			return import.meta.env.VITE_APPLICATION_INSIGHTS_KEY_UAT
		default:
			return import.meta.env.VITE_APPLICATION_INSIGHTS_KEY_DEV
	}
}

const instrumentationKey = getAppInsightsKey()
const reactPlugin: ReactPlugin = new ReactPlugin()
const appInsights: ApplicationInsights = new ApplicationInsights({
	config: {
		autoTrackPageVisitTime: true,
		correlationHeaderDomains: ['*.corestream.com'],
		disableFetchTracking: false,
		enableAjaxErrorStatusText: true,
		enableAjaxPerfTracking: true,
		enableAutoRouteTracking: true,
		enableCorsCorrelation: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		enableUnhandledPromiseRejectionTracking: true,
		extensions: [reactPlugin as unknown as ITelemetryPlugin],
		instrumentationKey,
		isBrowserLinkTrackingEnabled: true,
	},
})
appInsights.loadAppInsights()

export default (Component): any => withAITracking(reactPlugin, Component)
export { appInsights, reactPlugin }
