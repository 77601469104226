import { CategoryLevel, Offer } from 'types/offer'

export type OfferCardFieldResult = {
	vendor: Pick<Offer['vendor'], 'color' | 'logo' | 'name'>
} & Pick<Offer, Exclude<OfferCardKeys, 'vendor.color' | 'vendor.logo' | 'vendor.name'>>

// Type for card view - only essential display fields
export type OfferCardFields = {
	category: string
	categoryNames: string[]
	code: string
	cta: string
	description: string
	image: string
	level: string
	link: string
	objectID: string
	offerType: string
	paychexCouponCode: string
	paychexLink: string
	primaryCategoryLevel: CategoryLevel
	rank: string
	title: string
	'vendor.color': string
	'vendor.logo': string
	'vendor.name': string
}
type OfferCardKeys = (typeof OFFER_CARD_ATTRIBUTES)[number]

// Tuple type to ensure type safety of the attribute array
export const OFFER_CARD_ATTRIBUTES = [
	'category',
	'categoryNames',
	'code',
	'cta',
	'description',
	'image',
	'level',
	'link',
	'objectID',
	'offerType',
	'paychexCouponCode',
	'paychexLink',
	'primaryCategoryLevel',
	'rank',
	'title',
	'vendor.logo',
	'vendor.name',
] as const

// For detail view - all fields
export const OFFER_DETAIL_ATTRIBUTES = ['*'] as const

// Analytics mapping class
export class AnalyticsOfferCard {
	readonly category: string
	readonly code: string
	readonly indexName: string
	readonly level: string
	readonly link: string
	readonly name: string
	readonly product_id: string
	readonly rank: number
	readonly type: string
	readonly vendor: string
	readonly vendorLogo: string

	constructor(offer: OfferCardFieldResult, indexName: string) {
		this.category = offer.category
		this.code = offer.code || offer.paychexCouponCode || ''
		this.product_id = offer.objectID
		this.level = offer.level
		this.link = offer.link || offer.paychexLink || ''
		this.name = offer.title
		this.rank = Number(offer.rank)
		this.type = offer.offerType
		this.vendor = offer['vendor.name']
		this.vendorLogo = offer['vendor.logo']
		this.indexName = indexName
	}
}
