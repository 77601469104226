import { Button, Typography } from '@mui/material'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { routesMap } from 'routes/routes-map'

import { ThemePage } from '../../types'
import styles from './travel-section.module.css'

type TravelSectionProps = ThemePage['travel']

export function TravelSection({ cta, imageLink, subtitle, title }: TravelSectionProps) {
	const track = useTrackDetailedPageEvent()

	const onTravelCtaClick = () => {
		track({
			actionType: 'click',
			elementName: 'access travel cta',
			elementType: 'button',
		})
	}

	return (
		<div className={styles.travel}>
			<div className={styles['travel-content']}>
				<img alt='img' className={styles['travel-image']} src={imageLink} />
				<div className={styles['travel-body']}>
					<div className={styles['travel-copy']}>
						<Typography className={styles['travel-title']} variant='h2'>
							{title}
						</Typography>
						<Typography className={styles['travel-subtitle']} variant='h5-body'>
							{subtitle}
						</Typography>
					</div>
					{cta.visible && (
						<Button
							className={styles['travel-cta']}
							color='inherit'
							href={cta.link ? cta.link : routesMap.deals.travel.path}
							variant='outlined'
							onClick={onTravelCtaClick}
						>
							{cta.title}
						</Button>
					)}
				</div>
			</div>
		</div>
	)
}
