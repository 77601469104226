import { ReactPlugin, useAppInsightsContext } from '@microsoft/applicationinsights-react-js'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { selectIsOE, selectOEWindowsFetchStatus } from 'store/benefit-plans/selectors'
import { hasVBSelector } from 'store/site/selectors'
import { match } from 'ts-pattern'
import { SUCCESS } from 'types/status-types'

export function usePageTrack(): void {
	const [canSendTrack, setCanSendTrack] = useState(() => false)
	const reactPlugin: ReactPlugin = useAppInsightsContext()
	const location = useLocation()
	const isOe = useSelector(selectIsOE)
	const oeWindowFetchStatus = useSelector(selectOEWindowsFetchStatus)
	const hasVoluntaryBenefits = useSelector(hasVBSelector)

	useEffect(() => {
		const enableTrack = () => setCanSendTrack(true)
		if (document.readyState === 'complete') {
			enableTrack()
		} else {
			window.addEventListener('load', enableTrack)

			return () => window.removeEventListener('load', enableTrack)
		}
	}, [])

	useEffect(() => {
		const { pathname } = location
		const oeEvent: string = '?event=oe'
		const route = match({ isOe, path: pathname === '/' ? pathname : pathname.split('/')[1] })
			.with({ path: '/' }, () => 'Landing Page')
			.with({ isOe: true, path: 'enrollment' }, ({ path }) => `${path}${oeEvent}`)
			.with({ isOe: true, path: 'home' }, ({ path }) => `${path}${oeEvent}`)
			.otherwise(({ path }) => path)

		if (canSendTrack && ((hasVoluntaryBenefits && oeWindowFetchStatus === SUCCESS) || !hasVoluntaryBenefits)) {
			window?.analytics?.page(route)
			reactPlugin.trackPageView({ name: route, uri: location.pathname })
		}
	}, [canSendTrack, hasVoluntaryBenefits, isOe, location, location.pathname, oeWindowFetchStatus, reactPlugin])
}
