// A list of tracked local storage items
export const LOCAL_STORAGE_KEYS = {
	CompletedPostLoginLogic: 'hasCompletedPostLoginLogic',
	IsImpersonation: 'isImpersonation',
	SeenWizardIntroStep: 'hasSeenWizardIntroStep',
} as const

// Clear all local storage items
export function clearAllLocalStorageItems(): void {
	Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
		localStorage.removeItem(key)
	})
}
