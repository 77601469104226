export const routesMap = {
	account: '/account',
	adminLogin: '/accounts/login/adminlogin',
	autoQuote: '/auto-quote',
	benefitCategory: '/benefits/:categoryName',
	benefitPlan: '/benefits/:categoryName/:benefitPlanId',
	benefitPlanCategories: '/benefits/:categoryName/*',
	benefitsEnroll: '/enroll',
	benefitsEnrollConfirm: '/enroll/confirm',
	benefitsEnrollInfo: '/enroll/info',
	benefitsEnrollPlan: '/enroll/plan/:planId',
	callback: '/callback',
	comingSoon: '/coming-soon',
	confirmation: '/confirmation',
	dataUsePolicy: '/data-use-policy',
	deals: getDealsRoutes(),
	enrollmentRedirect: '/enrollment',
	error: '/error',
	faq: '/faq',
	forgotPassword: '/forgot-password',
	home: '/home',
	login: '/login',
	logout: '/logout',
	ltc: '/ltc/:planId',
	missing: '/404',
	myProfile: '/my-profile',
	register: '/register',
	saml: '/saml',
	search: '/search',
	start: '/',
	survey: getSurveyRoutes(),
	terms: '/terms',
	themes: getThemeRoutes(),
	workerRedirect: '/worker-redirect',
}

export const authRedirectRoutes = [routesMap.callback, routesMap.saml]

function getDealsRoutes() {
	const base = 'deals'

	return {
		_route: `${base}/*`,
		base: `/${base}`,
		travel: {
			_route: `travel`,
			path: `/${base}/travel`,
		},
	}
}

function getSurveyRoutes() {
	const base = '/survey'

	return {
		_route: `${base}/*`,
		analyzing: (formSubmissionId: string) => `${base}/${formSubmissionId}/analyzing`,
		base,
		latestResults: `${base}/results`,
		resultsbyId: (formSubmissionId: string) => `${base}/${formSubmissionId}/results`,
		survey: (surveyId: string) => `${base}/${surveyId}`,
	}
}

function getThemeRoutes() {
	const base = '/themes'

	return {
		_route: `${base}/*`,
		base,
		theme: (themeName: string) => `${base}/${themeName}`,
	}
}

export const benefitRoutes = {
	benefit: getPlanRoutes(),
	/**
	 * tacked on to the end of the benefit route
	 */
	takeover: getTakeoverPaths(),
	wizard: getWizardRoutes(),
}

function getPlanRoutes() {
	const base = '/plan'

	return {
		_route: `${base}/:benefitPlanId/*`,
		base,
		category: (category: string) => `/benefits/${category}`,
		evidenceOfInsurability: (benefitPlanId: number) => `${base}/${benefitPlanId}/eoi`,
		personal: (benefitPlanId: number) => `${base}/${benefitPlanId}/personal`,
		plan: (benefitPlanId: number) => `${base}/${benefitPlanId}`,
		receipt: (benefitPlanId: number) => `${base}/${benefitPlanId}/receipt`,
	}
}

function getWizardRoutes() {
	const base = '/wizard'

	return {
		_route: `${base}/*`,
		base,
		evidenceOfInsurability: `${base}/eoi`,
		intro: `${base}/intro`,
		moreCoverage: `${base}/more-coverage`,
		noEnrollments: `${base}/no-enrollments`,
		personal: `${base}/personal`,
		plan: (benefitPlanId: number) => `${base}/plan/${benefitPlanId}`,
		receipt: `${base}/receipt`,
	}
}

export const routeParams = {
	hideWizard: 'hideWizard', // eg. ?hideWizard=true
	intro: 'intro', // eg. ?intro=true
	takeoverNav: 'takeoverNav', // eg. ?takeoverNav=parent
}

function getTakeoverPaths() {
	const base = 'takeover'

	return {
		grandfathered: {
			_route: `${base}/grandfathered/:grandfatheredPlanId/*`,
			path: `${base}/grandfathered/`,
		},
		parent: {
			_route: `${base}/parent/:grandfatheredPlanId/*`,
			path: `${base}/parent/`,
		},
	}
}

export const accountRoutes = {
	deductions: '/account/deductions',
	deductionsUri: 'deductions',
	enrollments: '/account/enrollments',
	enrollmentsUri: 'enrollments',
	profile: '/account/profile',
	profileUri: 'profile',
}
