import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { Loading } from 'components/loading'
import { appInsights } from 'config/app-insights'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { routesMap } from 'routes/routes-map'
import { store } from 'store/store'
import { actions } from 'store/user/slice'
import { clearUTMFromSessionStorage } from 'utils/cookies/utm-tracking'

const timeoutErrorMessage = 'There was an issue completing your request.'

export const axiosInstance: AxiosInstance = axios.create({
	responseType: 'json',
	timeout: 60000,
	timeoutErrorMessage,
	withCredentials: true,
})

export const petAxiosInstance: AxiosInstance = axios.create({
	responseType: 'json',
	timeout: 60000,
	timeoutErrorMessage,
	withCredentials: true,
})

export const AxiosInterceptor = ({ children }) => {
	const dispatch = useAppDispatch()
	const navigate = useNavigate()

	const sessionToken = useAppSelector((s) => s.oidc.user?.access_token)
	const accessTokenReady = useAppSelector((s) => s.user.accessTokenReady)
	const isLoggedIn = useAppSelector((s) => s.user.isLoggedIn)

	const [isSet, setIsSet] = useState(false)

	useEffect(() => {
		const successHandler = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => response.data

		const errHandler = (error) => {
			appInsights.trackException({ exception: error })

			if (error.response?.status === 401 && !window.location.pathname.includes('login')) {
				clearUTMFromSessionStorage()
				navigate(routesMap.logout)

				return store.getState().user.userManager.signoutRedirect()
			} else {
				return Promise.reject({ ...error })
			}
		}

		if (!sessionToken) {
			// eslint-disable-next-line no-console
			console.warn('[axios interceptor setup] addToken | no session token found')
		} else {
			// eslint-disable-next-line no-console
			console.debug('[axios interceptor setup] addToken | session token found')
		}

		const addToken = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
			if (sessionToken) {
				config.headers.set('Authorization', `Bearer ${sessionToken}`)
			}

			return config
		}

		const addClientHeader = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
			const { organizationId, organizationName } = store.getState().site.siteInfo
			const timezoneOffset = new Date().getTimezoneOffset()

			if (organizationId) {
				config.headers.set('Tenant', organizationId)
				config.headers.set('TenantName', organizationName)
				config.headers.set('x-timezone-offset', timezoneOffset)
			}

			return config
		}

		const responseinterceptor = axiosInstance.interceptors.response.use(successHandler, errHandler)
		const requestInterceptor = axiosInstance.interceptors.request.use(
			(config) => addToken(addClientHeader(config)),
			errHandler,
		)

		const petReqInterceptor = petAxiosInstance.interceptors.request.use(
			(config) => addToken(addClientHeader(config)),
			errHandler,
		)
		const petResInterceptor = petAxiosInstance.interceptors.response.use(successHandler, errHandler)

		setIsSet(true)

		return () => {
			axiosInstance.interceptors.response.eject(responseinterceptor)
			axiosInstance.interceptors.request.eject(requestInterceptor)

			petAxiosInstance.interceptors.request.eject(petReqInterceptor)
			petAxiosInstance.interceptors.response.eject(petResInterceptor)
		}
	}, [navigate, sessionToken])

	useEffect(() => {
		if (!!sessionToken && accessTokenReady && !isLoggedIn) {
			// eslint-disable-next-line no-console
			console.debug(
				`[axios setup] dispatching user.isLoggedIn to true | token ready - oidc ready - isLoggedIn: ${isLoggedIn}`,
			)
			dispatch(actions.setIsLoggedIn(true))
		}
	}, [accessTokenReady, dispatch, isLoggedIn, sessionToken])

	if (!isSet)
		return (
			<div
				// doing this on purpose to force us to take a longer look @ the loading experience
				// this renders when nothing else is ready
				role='presentation'
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '100vh',
					justifyContent: 'center',
				}}
			>
				<Loading />
			</div>
		)

	return children
}
