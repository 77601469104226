import { Close, Search } from '@mui/icons-material'
import { Button, IconButton, Input, InputAdornment } from '@mui/material'
import { useIsFetching } from '@tanstack/react-query'
import { searchKeys } from 'api/search'
import { generateSearchUrl } from 'feature/search/utils/url-mapping'
import { Transition } from 'hooks/use-navigation-popup'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BlockerFunction, useBlocker, useLocation, useNavigate } from 'react-router-dom'
import { routesMap } from 'routes/routes-map'
import { useSearchSlice } from 'state/store'

import styles from './search-box.module.css'

export type SearchBoxProps = {
	closeMenu?: () => void
	id?: string
	onCancelClick: () => void
	track: (query: any) => unknown
}

const SearchBox: FC<SearchBoxProps> = memo(({ closeMenu, id, onCancelClick, track }: SearchBoxProps) => {
	const location = useLocation()
	const navigate = useNavigate()

	const { query, setQuery } = useSearchSlice((s) => ({
		query: s.search.query,
		setQuery: s.search.setQuery,
	}))

	const lastStateQueryValue = useRef(query)

	// Sync local query value with state query value
	useEffect(() => {
		if (lastStateQueryValue.current !== query) {
			lastStateQueryValue.current = query
			setLocalQueryValue(query)
		}
	}, [query])

	const isLoading = useIsFetching({
		exact: false,
		queryKey: searchKeys.search.all(),
	})

	const [localQueryValue, setLocalQueryValue] = useState(query)

	const clearSearchValue = useCallback((): void => {
		setQuery('')
		setLocalQueryValue('')
		track('')
	}, [setQuery, track])

	const handleNavigateAwayFromSearch = useCallback<BlockerFunction>(
		(history: Transition) => {
			if (
				!history.nextLocation.pathname.includes('search') &&
				location.pathname.includes('search') &&
				localQueryValue.length > 0
			) {
				clearSearchValue()
			}

			return false
		},
		[location.pathname, localQueryValue.length, clearSearchValue],
	)

	useBlocker(handleNavigateAwayFromSearch)

	/**
	 * kicks off the search
	 * @param searchVal value to search on
	 * @returns nothing
	 */
	const search = useCallback(
		(searchVal: string): void => {
			setQuery(searchVal)
			navigate(`${routesMap.search}${generateSearchUrl({ q: searchVal })}`)
			track(searchVal)
			document.getElementById('searchInput')?.blur()
		},
		[navigate, setQuery, track],
	)

	// /**
	//  * Trigger focus on page render
	//  */
	const onKeyPressed = (e): void => {
		if (e.key === 'Enter') {
			search(e.target.value)
		}
	}

	const onSearchIconClick = useCallback(
		(e): void => {
			e.preventDefault()
			search(localQueryValue)
		},
		[localQueryValue, search],
	)

	const onChange = (e): void => {
		e.preventDefault()

		setLocalQueryValue(e.target.value)
		if (closeMenu) closeMenu()
	}

	const isClearIconVisible = localQueryValue.length > 0

	const endAdornment: JSX.Element = useMemo(
		() => (
			<InputAdornment position='end'>
				<div className={styles['icons']}>
					{isClearIconVisible && (
						<IconButton
							aria-label='Clear Search'
							id='button-clear-search'
							size='large'
							onClick={() => clearSearchValue()}
						>
							{<Close />}
						</IconButton>
					)}
					<IconButton aria-label='Search' edge='end' id='button-search' size='large' onClick={onSearchIconClick}>
						<Search />
					</IconButton>
				</div>
			</InputAdornment>
		),
		[clearSearchValue, isClearIconVisible, onSearchIconClick],
	)

	return (
		<div className={styles['container']}>
			<div className={styles['input-container']}>
				<Input
					autoComplete='cs-search'
					className={styles['search-bar']}
					disabled={!!isLoading}
					disableUnderline
					endAdornment={endAdornment}
					id={id ?? 'searchInput'}
					name='Benefits and Offers Search'
					placeholder='What can we help you find?'
					value={localQueryValue}
					inputProps={{
						'aria-label': 'What can we help you find?',
					}}
					onChange={onChange}
					onKeyDown={onKeyPressed}
				/>
			</div>
			<Button
				aria-label={'Cancel Search'}
				className={styles['cancel']}
				color='error'
				variant='text'
				onClick={onCancelClick}
			>
				CANCEL
			</Button>
		</div>
	)
})

SearchBox.displayName = 'SearchBox'

export default SearchBox
