import { SearchFilters, SearchFilterValue } from 'api/search/types/search'

type FilterConfig = {
	blacklist?: string[]
	whitelist?: string[]
}

type FilterValue = (number | string)[] | boolean | string

/**
 * Converts filter object to Algolia filter string
 */
export function buildAlgoliaFilters(filters: SearchFilters, config?: FilterConfig): string {
	const { blacklist, whitelist } = config ?? {}

	return Object.entries(filters)
		.filter(([key]) => {
			if (whitelist?.length) return whitelist.includes(key)
			if (blacklist?.length) return !blacklist.includes(key)

			return true
		})
		.sort(([keyA], [keyB]) => {
			// Sort NOT_ prefixed keys first
			const isNotA = keyA.startsWith('NOT_')
			const isNotB = keyB.startsWith('NOT_')
			if (isNotA && !isNotB) return -1
			if (!isNotA && isNotB) return 1

			return keyA.localeCompare(keyB)
		})
		.map(([key, value]) => {
			const normalizedValue = normalizeFilterValue(value)

			return buildFilterExpression(key, normalizedValue)
		})
		.join(' AND ')
}

/**
 * Merges multiple filter objects, handling duplicates
 */
export function mergeFilters(...filterSets: SearchFilters[]): SearchFilters {
	return filterSets.reduce(
		(acc, filters) => ({
			...acc,
			...filters,
		}),
		{},
	)
}

/**
 * Normalizes filter values to a consistent format
 */
export function normalizeFilterValue(value: SearchFilterValue): boolean | string {
	if (value === 'true') return true
	if (value === 'false') return false

	return value as string
}

/**
 * Builds filter expression for a single key-value pair
 */
function buildFilterExpression(key: string, value: FilterValue): string {
	let filterString = ''
	const isNegative = key.startsWith('NOT_')
	const actualKey = isNegative ? key.replace('NOT_', '') : key

	if (Array.isArray(value)) {
		filterString = `(${value.map((v) => `${isNegative ? 'NOT ' : ''}${actualKey}:"${v}"`).join(' OR ')})`
	} else if (typeof value === 'boolean') {
		filterString = `${isNegative ? 'NOT ' : ''}${actualKey}:${value}`
	} else {
		filterString = `${isNegative ? 'NOT ' : ''}${actualKey}:"${value}"`
	}

	return filterString
}
